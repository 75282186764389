import { GetRoute, RouteVariables } from 'types/routes'
import { Language } from 'types/language'
import { ROUTES } from 'constants/routes'
import { stringify } from 'qs'
import { NextRouter } from 'next/router'

/**
 * Gets route based upon current path and language
 *
 * @param {string} path
 * @param {Language} language
 * @returns {string|'home'}
 */
const getRouteKey = (path: string, language: Language): string => {
  // Check if path is a subroute of the producers path
  const regex = new RegExp(ROUTES.PRODUCERS[language] + '/.+$')

  if (regex.exec(path)) {
    return path.includes(ROUTES.REGISTRATION[language]) ? 'registration' : 'producerInfo'
  }

  // Return the route key or return home if it couldn't be found
  return (
    Object.keys(routes(language)).find((key: string) => {
      const pathHasNoStartingSlash = !routes(language)[key].startsWith('/')
      const pathToMatch = pathHasNoStartingSlash ? `/${routes(language)[key]}` : routes(language)[key]
      return path === pathToMatch
    }) ?? 'home'
  )
}

/**
 * Translates path to chosen language
 *
 * @param {NextRouter} router
 * @param {Language} newLanguage
 * @returns {Pick<NextRouter, 'asPath'>}
 */
export const translateRoute = (router: NextRouter, newLanguage: Language): Pick<NextRouter, 'asPath'> => {
  const pathInOldLanguage = router.asPath.split('?')[0]

  // Add old language to path
  const pathWithLanguage = `/${router.locale}${pathInOldLanguage}`

  // Gets route key
  const routeKey = getRouteKey(pathWithLanguage.replace(/\/$/, ''), router.locale as Language)

  // Get slug and rest of query
  const { slug, ...query } = router.query

  return {
    asPath: `${replaceRouteVariable(routes(newLanguage)[routeKey], RouteVariables.SLUG, slug as string)}${stringify(query, { addQueryPrefix: true })}`
  }
}

/**
 * Replaces the slug in the route if it's not undefined
 *
 * @param route
 * @param variable
 * @param slug
 */
const replaceRouteVariable = (route: string, variable: RouteVariables, slug: string | undefined): string => {
  return route.replace(variable, slug !== undefined ? slug : variable)
}

/**
 * Replaces multiple slugs in the route if it's not undefined
 *
 * @param route
 * @param variables
 * @param slugs
 */
const replaceNestedRouteVariable = (route: string, variables: RouteVariables[], slugs: string[] | undefined): string => {
  return variables.reduce((prev: string, curr, idx) => prev.replace(curr, slugs?.length ? slugs[idx] : curr), route)
}

/**
 * Returns all routes
 * @param {string} lang
 * @param {string} slug
 * @param {string[]} slugs
 * @returns {GetRoute}
 */
export const routes = (lang: string, slug?: string, slugs?: string[]): GetRoute =>
  ({
    about: `/${lang}/${ROUTES.ABOUT[lang]}`,
    becomeProducer: `/${lang}/${ROUTES.BECOME_PRODUCER[lang]}`,
    becomeProducerForm: `/${lang}/${ROUTES.BECOME_PRODUCER_REQUEST[lang]}`,
    blog: `/${lang}/${ROUTES.BLOG[lang]}`,
    blogArticle: replaceRouteVariable(`/${lang}/${ROUTES.BLOG_ARTICLE[lang]}`, RouteVariables.ARTICLE, slug),
    boltFix: `/${lang}/${ROUTES.BOLT_FIX[lang]}`,
    boltGo: `/${lang}/${ROUTES.BOLT_GO[lang]}`,
    brusselsAirport: `/${lang}/${ROUTES.BRUSSELS_AIRPORT[lang]}`,
    careerDetail: replaceRouteVariable(`/${lang}/${ROUTES.CAREER_DETAIL[lang]}`, RouteVariables.SLUG, slug),
    careers: `/${lang}/${ROUTES.CAREERS[lang]}`,
    dynamicPricing: `/${lang}/${ROUTES.DYNAMIC_PRICES[lang]}`,
    encyclopedia: `/${lang}/${ROUTES.ENCYCLOPEDIA[lang]}`,
    encyclopediaArticle: replaceNestedRouteVariable(
      `/nl/${ROUTES.ENCYCLOPEDIA_ARTICLE['nl']}`,
      [RouteVariables.CATEGORY, RouteVariables.SUB_CATEGORY, RouteVariables.ARTICLE],
      slugs
    ),
    encyclopediaCategory: replaceRouteVariable(`/nl/${ROUTES.ENCYCLOPEDIA_CATEGORY['nl']}`, RouteVariables.CATEGORY, slug),
    encyclopediaSubCategory: replaceNestedRouteVariable(
      `/nl/${ROUTES.ENCYCLOPEDIA_SUB_CATEGORY['nl']}`,
      [RouteVariables.CATEGORY, RouteVariables.SUB_CATEGORY],
      slugs
    ),
    energyGarden: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN[lang]}`, RouteVariables.SLUG, slug),
    energyGardenConfirmation: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_CONFIRMATION[lang]}`, RouteVariables.SLUG, slug),
    energyGardenContact: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_CONTACT[lang]}`, RouteVariables.SLUG, slug),
    energyGardenFaq: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_FAQ[lang]}`, RouteVariables.SLUG, slug),
    energyGardenHowItWorks: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_HOW_IT_WORKS[lang]}`, RouteVariables.SLUG, slug),
    energyGardenInfoSession: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_INFO_SESSION[lang]}`, RouteVariables.SLUG, slug),
    energyGardenOrder: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_ORDER[lang]}`, RouteVariables.SLUG, slug),
    energyGardenTerms: replaceRouteVariable(`/${lang}/${ROUTES.ENERGY_GARDEN_TERMS[lang]}`, RouteVariables.SLUG, slug),
    energyGardens: `/${lang}/${ROUTES.ENERGY_GARDENS_OVERVIEW[lang]}`,
    faq: `/${lang}/${ROUTES.FAQ[lang]}`,
    festivals: `/${lang}/${ROUTES.FESTIVALS[lang]}`,
    home: `/${lang}`,
    honestEnergy: `/nl/${ROUTES.HONEST_ENERGY['nl']}`,
    miniGenerators: `/${lang}/${ROUTES.MINI_GENERATORS[lang]}`,
    move: `/${lang}/${ROUTES.MOVE[lang]}`,
    moveLP: `/${lang}/${ROUTES.MOVE_LP[lang]}`,
    noEan: `/${lang}/no-ean`,
    oldTerms: replaceRouteVariable(`/${lang}/${ROUTES.OLD_TERMS_AND_CONDITIONS[lang]}`, RouteVariables.VERSION, slug),
    positiveEnergy: `/nl/${ROUTES.POSITIVE_ENERGY['nl']}`,
    priceList: `/${lang}/${ROUTES.PRICELISTS[lang]}`,
    pricing: `/${lang}/${ROUTES.PRICING[lang]}`,
    privacy: `/${lang}/${ROUTES.PRIVACY[lang]}`,
    producerInfo: replaceRouteVariable(`/${lang}/${ROUTES.PRODUCER_INFO[lang]}`, RouteVariables.SLUG, slug),
    producers: `/${lang}/${ROUTES.PRODUCERS[lang]}`,
    projectDetail: replaceRouteVariable(`/${lang}/${ROUTES.PROJECT_DETAIL[lang]}`, RouteVariables.SLUG, slug),
    projects: `/${lang}/${ROUTES.PROJECTS[lang]}`,
    referral: `/${lang}/${ROUTES.REFERRAL[lang]}`,
    registration: replaceRouteVariable(`/${lang}/${ROUTES.REGISTRATION[lang]}`, RouteVariables.SLUG, slug),
    registrationLoading: `/${lang}/${ROUTES.REGISTRATION_LOADING[lang]}`,
    settlement: `/${lang}/${ROUTES.SETTLEMENT[lang]}`,
    simulation: `/${lang}/${ROUTES.SIMULATION[lang]}`,
    springCampaign: `/nl/${ROUTES.SPRING_CAMPAIGN['nl']}`,
    terms: `/${lang}/${ROUTES.TERMS_AND_CONDITIONS[lang]}`
  }) satisfies GetRoute
