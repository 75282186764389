import '@boltenergy-be/design-system/dist/assets/foundations.css'
import { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import { AppProps } from 'next/app'
import Head from 'next/head'
import localFont from 'next/font/local'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { Provider } from 'react-redux'
import { store } from 'store'
import nextI18NextConfig from '../../next-i18next.config.js'
import { startUp } from 'utils/startUp'
import 'slick-carousel/slick/slick.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'next-cloudinary/dist/cld-video-player.css'
import 'styles/globals.scss'
import mixpanel from 'mixpanel-browser'
import { MIXPANEL_ID } from 'constants/envs'
import { checkIsStaging } from 'utils/helpers'
import StoreInitializer from 'store/StoreInitializer'
import 'dayjs/locale/fr'
import 'dayjs/locale/nl'
import dayjs from 'dayjs'
import { initializeBugsnag } from 'utils/logging'
import ErrorBoundary from 'features/error-boundary/ErrorBoundary'

let initializedMixpanel = false

const commutersSans = localFont({
  display: 'swap',
  variable: '--commuters-sans-font',
  src: [
    {
      path: '../../public/fonts/commuters-sans-regular-webfont.woff2',
      style: 'normal',
      weight: '400'
    },
    {
      path: '../../public/fonts/commuters-sans-light-webfont.woff2',
      style: 'normal',
      weight: '300'
    },
    {
      path: '../../public/fonts/commuters-sans-bold-webfont.woff2',
      style: 'normal',
      weight: '700'
    },
    {
      path: '../../public/fonts/commuters-sans-semibold.woff2',
      style: 'normal',
      weight: '600'
    }
  ]
})

const App = ({ Component, pageProps, router }: AppProps) => {
  // Init Mixpanel only once
  // Not inside usEffect due to useEffect here is being loaded after useEffect inside a page component
  if (!initializedMixpanel) {
    initializedMixpanel = true
    const isStaging = checkIsStaging()
    mixpanel.init(MIXPANEL_ID, {
      api_host: 'https://api-eu.mixpanel.com', // For EU Data Residency
      debug: isStaging,
      ignore_dnt: isStaging, // Ignore Do Not Track requests and always sends the events on staging/preview
      persistence: 'localStorage'
    })
  }

  // Initialize dayjs with the correct locale
  const { i18n } = useTranslation()
  dayjs.locale(i18n.resolvedLanguage)

  /**
   * Triggered on first render
   * Executes the startUp function
   */
  useEffect(() => {
    if (router.locale !== 'default') {
      startUp()
    }

    // Load bugsnag
    if (!Bugsnag.isStarted()) {
      initializeBugsnag()
    }
  }, [])

  /**
   * Don't render pages for 'default' locale:
   * in Next.js, pages will be build for all locales, but because we're using the middleware "hack" to prefix routes with the locale,
   * we should prevent rendering by returning null (will speed up the build).
   * See https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
   */
  if (router.locale === 'default') {
    return null
  }

  return (
    <>
      <style jsx global>{`
        :root {
          --font-family: ${commutersSans.style.fontFamily}, sans-serif;
        }
      `}</style>

      <Provider store={store}>
        <StoreInitializer>
          {/* Default SEO */}
          <Head>
            <link rel="icon" href="/favicon.ico" />
            {/* TODO: remove maximum-scale because it's a bad practise */}
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1.0" />
            <meta
              property="og:image"
              content="https://res.cloudinary.com/bolt-energie/image/upload/v1720622482/website/default-seo.png"
              key="image"
            />
            <meta name="twitter:site" content="@boltenergie" />
            <meta name="twitter:card" content="summary_large_image" />
          </Head>

          <ErrorBoundary>
            {/* Page Component */}
            <Component {...pageProps} />

            {/* Modal parent */}
            <div id="modal-root" />
          </ErrorBoundary>
        </StoreInitializer>
      </Provider>
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
