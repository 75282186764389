/* eslint-disable @next/next/no-html-link-for-pages */
import React, { Component, PropsWithChildren } from 'react'
import { log } from 'utils/logging'
import { Criticality } from 'types/logging'
import Layout from 'components/layout/Layout'
import { Heading } from '@boltenergy-be/design-system'
import { ErrorBoundaryState } from './types'
import styles from './ErrorBoundary.module.scss'
import { store } from 'store/index'
import classNames from 'classnames'
import { Language } from 'types/language'

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, path: '' }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error, path: document.location.pathname }
  }

  componentDidCatch(error, errorInfo) {
    const { app, customer, marketing } = store.getState()
    // Log error in Bugsnag
    log({
      error,
      identifier: '[ErrorBoundary]',
      metaData: {
        errorBoundaryInfo: {
          error,
          errorInfo,
          app,
          customer,
          marketing,
          path: document?.location?.pathname
        }
      },
      criticality: Criticality.HIGH
    })
  }

  render() {
    if (this.state.hasError) {
      const language = document?.location.pathname.includes('/fr') ? Language.FRENCH : Language.DUTCH

      if (this.state.path !== document.location.pathname) {
        // Reset error boundary when switching route
        this.setState({ hasError: false, error: null, path: '' })
      }

      return (
        <Layout seo={{ title: 'Error', noindex: true }}>
          <section className={classNames('section container', styles.boundary)}>
            <Heading as="h1" variant="h3">
              {language === Language.FRENCH ? 'Une erreur est survenue !' : 'Er ging iets mis!'}
            </Heading>
            {language === Language.FRENCH ? (
              <p>
                Veuillez réessayer plus tard ou revenir à{' '}
                <a href="/" rel="noreferrer noopener">
                  la page d&apos;accueil
                </a>
              </p>
            ) : (
              <p>
                Probeer het later opnieuw of ga terug naar{' '}
                <a href="/" rel="noreferrer noopener">
                  de homepagina
                </a>
              </p>
            )}
          </section>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
