import Header from 'components/header/Header'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'next-i18next'
import { LayoutProps as Props } from './types'
import Footer from 'components/footer/Footer'
import Banner from 'components/banner/Banner'
import { useRouter } from 'next/router'
import { routes } from 'utils/routes'
import { useStoreSelector } from 'hooks/store'
import Seo from 'components/seo/Seo'
import { trackEvent } from 'utils/tracking'
import { CommonTrackingEvents } from 'types/tracking'
import SimulationTypeToggle from './SimulationTypeToggle/SimulationTypeToggle'

const Layout = ({ hideBanner, hideHeader, hideFooter, localized, children, extraSpaceFooter, seo }: PropsWithChildren<Props>) => {
  // Redux store
  const { resetButton, partnerID, simulationType } = useStoreSelector((store) => store.marketing)
  const { didSimulation } = useStoreSelector((store) => store.customer.flows.simulation)

  // i18n
  const { t } = useTranslation(['common', 'seo'])

  // Next Router
  const router = useRouter()
  const { locale } = router

  /**
   * Handles the banner on click event
   * Shows simulation or redirects to producer page if simulation has been done
   */
  const handleOnBannerClick = () => {
    if (didSimulation) {
      return router.push(routes(locale).producers)
    } else {
      // Track 'Simulation Started' event @Semetis
      trackEvent(CommonTrackingEvents.SIMULATION_STARTED)

      return router.push(routes(locale).simulation)
    }
  }

  return (
    <>
      {/* SEO */}
      <Seo title={seo?.title || t('seo:commonSeoTitle')} {...seo} />

      {!hideHeader && <Header toggleHeaderOnScroll {...{ localized }} />}

      {!hideBanner && <Banner onClickHandler={handleOnBannerClick} />}

      {children}

      {partnerID && (resetButton || simulationType.length > 1) && <SimulationTypeToggle />}

      {!hideFooter && <Footer {...{ extraSpaceFooter }} />}
    </>
  )
}

Layout.defaultProps = {
  hideHeader: false
}

export default Layout
